import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
    hideHeader,
    hideBottomNav,
    hideSideDrawer,
} from "../../../state/actions";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "reactstrap";
import * as Strapi from "../../../hooks/strapi";

import HomeCard from "../../molecules/HomeCard/HomeCard";

import "./home.scss";
import Link from "../../atoms/Link/Link";
import PlainText from "../../atoms/PlainText/PlainText";

const Home = () => {
    const dispatch = useDispatch();
    const global = Strapi.useGlobal();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());
        dispatch(hideSideDrawer());

        document.body.classList.add("page-home");

        return () => {
            document.body.classList.remove("page-home");
        };
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <div className="home">
                        <div className="home__logo">
                            <GatsbyImage
                                image={getImage(global.logo_ue.localFile)}
                                alt={global.logo_ue.alternativeText}
                            />
                        </div>
                        <HomeCard />
                        <div className="bottom-nav--declaration" style={{ background: 'transparent' }}>
                            <Link
                                to="/deklaracja-dostepnosci"
                            >
                                <PlainText className="btn--label btn--label__sm text-center">
                                    Deklaracja dostępności
                                </PlainText>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
